import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  TextField, 
  Button, 
  Paper, 
  Grid, 
  Card,
  CardContent,
  Divider,
  Rating,
  FormLabel,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { 
  getCurrentWeekKey, 
  createEmptyWeeklyResponses, 
  saveWeeklyResponses, 
  loadWeeklyResponses,
  formatWeekDisplay
} from '../utils/weeklyUtils';

function WeeklyReflection() {
  const { currentUser } = useAuth();
  const [weeklyResponses, setWeeklyResponses] = useState(createEmptyWeeklyResponses());
  const [savedResponses, setSavedResponses] = useState(null);
  const [saveStatus, setSaveStatus] = useState('');
  const [currentWeek, setCurrentWeek] = useState(getCurrentWeekKey());
  const [loading, setLoading] = useState(true);

  // Load saved responses for current week
  useEffect(() => {
    async function fetchWeeklyResponses() {
      if (!currentUser) {
        setLoading(false);
        return;
      }
      
      try {
        const weekKey = getCurrentWeekKey();
        setCurrentWeek(weekKey);
        
        // Load this week's responses
        const weekResponses = await loadWeeklyResponses(currentUser.email, weekKey);
        if (weekResponses) {
          setWeeklyResponses(weekResponses);
          setSavedResponses(weekResponses);
        } else {
          setWeeklyResponses(createEmptyWeeklyResponses());
          setSavedResponses(null);
        }
      } catch (error) {
        console.error("Error loading weekly responses:", error);
      } finally {
        setLoading(false);
      }
    }
    
    fetchWeeklyResponses();
  }, [currentUser]); // Only run when currentUser changes

  // Handle text input changes
  const handleInputChange = useCallback((field) => (event) => {
    setWeeklyResponses(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  }, []);

  // Handle rating changes
  const handleRatingChange = useCallback((field) => (event, newValue) => {
    setWeeklyResponses(prev => ({
      ...prev,
      [field]: newValue
    }));
  }, []);

  // Save responses to Firestore
  const handleSave = useCallback(async () => {
    if (!currentUser) return;
    
    try {
      setSaveStatus('Saving...');
      await saveWeeklyResponses(currentUser.email, currentWeek, weeklyResponses);
      setSavedResponses({...weeklyResponses});
      setSaveStatus('Weekly reflection saved successfully!');
    } catch (error) {
      console.error("Error saving weekly reflection:", error);
      setSaveStatus('Error saving weekly reflection. Please try again.');
    }
    
    // Clear save status after 3 seconds
    setTimeout(() => {
      setSaveStatus('');
    }, 3000);
  }, [currentUser, currentWeek, weeklyResponses]);

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
          <Typography variant="h6">Loading weekly reflection data...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Weekly Reflection
        </Typography>
        
        <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            {formatWeekDisplay(currentWeek)}
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="What was the highlight of this week?"
                variant="outlined"
                value={weeklyResponses?.weeklyHighlight || ''}
                onChange={handleInputChange('weeklyHighlight')}
                multiline
                rows={3}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Who were you grateful for?"
                variant="outlined"
                value={weeklyResponses?.gratefulFor || ''}
                onChange={handleInputChange('gratefulFor')}
                multiline
                rows={3}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={weeklyResponses?.enteredInWorkday || false}
                    onChange={(e) => setWeeklyResponses(prev => ({
                      ...prev,
                      enteredInWorkday: e.target.checked
                    }))}
                    name="enteredInWorkday"
                    color="primary"
                  />
                }
                label="Did you enter that in Workday?"
                sx={{ mt: 1 }}
              />
            </Grid>
            
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Congruency: Values, Reflection, Recommitment
              </Typography>
              
              <TextField
                fullWidth
                label="Ground: Review your values"
                variant="outlined"
                value={weeklyResponses?.wtw?.values || ''}
                onChange={(e) => setWeeklyResponses(prev => ({
                  ...prev,
                  wtw: { ...prev.wtw, values: e.target.value }
                }))}
                multiline
                rows={2}
                sx={{ mb: 2 }}
              />
              
              <TextField
                fullWidth
                label="Reflect: on a moment of incongruency"
                variant="outlined"
                value={weeklyResponses?.wtw?.reflectIncontingency || ''}
                onChange={(e) => setWeeklyResponses(prev => ({
                  ...prev,
                  wtw: { ...prev.wtw, reflectIncontingency: e.target.value }
                }))}
                multiline
                rows={2}
                sx={{ mb: 2 }}
              />
              
              <TextField
                fullWidth
                label="Recommit: How will you close the gap?"
                variant="outlined"
                value={weeklyResponses?.wtw?.recommitCloseGap || ''}
                onChange={(e) => setWeeklyResponses(prev => ({
                  ...prev,
                  wtw: { ...prev.wtw, recommitCloseGap: e.target.value }
                }))}
                multiline
                rows={2}
              />
            </Grid>
            
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                TED: Problem, Shift, Action
              </Typography>
              
              <TextField
                fullWidth
                label="What problem did you encounter?"
                variant="outlined"
                value={weeklyResponses?.dt2ted?.problem || ''}
                onChange={(e) => setWeeklyResponses(prev => ({
                  ...prev,
                  dt2ted: { ...prev.dt2ted, problem: e.target.value }
                }))}
                multiline
                rows={2}
                sx={{ mb: 2 }}
              />
              
              <TextField
                fullWidth
                label="What shift needed to happen?"
                variant="outlined"
                value={weeklyResponses?.dt2ted?.shift || ''}
                onChange={(e) => setWeeklyResponses(prev => ({
                  ...prev,
                  dt2ted: { ...prev.dt2ted, shift: e.target.value }
                }))}
                multiline
                rows={2}
                sx={{ mb: 2 }}
              />
              
              <TextField
                fullWidth
                label="What action did you take?"
                variant="outlined"
                value={weeklyResponses?.dt2ted?.action || ''}
                onChange={(e) => setWeeklyResponses(prev => ({
                  ...prev,
                  dt2ted: { ...prev.dt2ted, action: e.target.value }
                }))}
                multiline
                rows={2}
              />
            </Grid>
            
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                What else do you want to capture from this week? 
              </Typography>
              
              <TextField
                fullWidth
                label="Big wins?"
                variant="outlined"
                value={weeklyResponses?.wins || ''}
                onChange={handleInputChange('wins')}
                multiline
                rows={3}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Major challenges?"
                variant="outlined"
                value={weeklyResponses?.challenges || ''}
                onChange={handleInputChange('challenges')}
                multiline
                rows={3}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Major lessons learned?"
                variant="outlined"
                value={weeklyResponses?.lessons || ''}
                onChange={handleInputChange('lessons')}
                multiline
                rows={3}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="What will you focus on next week?"
                variant="outlined"
                value={weeklyResponses?.nextWeekFocus || ''}
                onChange={handleInputChange('nextWeekFocus')}
                multiline
                rows={3}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="What are you grateful for this week?"
                variant="outlined"
                value={weeklyResponses?.gratitude || ''}
                onChange={handleInputChange('gratitude')}
                multiline
                rows={3}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Box sx={{ mb: 2 }}>
                <FormLabel component="legend">How would you rate your overall wellbeing this week?</FormLabel>
                <Rating
                  name="wellbeing-rating"
                  value={weeklyResponses?.wellbeingRating || 3}
                  onChange={handleRatingChange('wellbeingRating')}
                  max={5}
                  size="large"
                  sx={{ mt: 1 }}
                />
              </Box>
            </Grid>
            
            <Grid item xs={12}>
              <Box>
                <FormLabel component="legend">How would you rate your progress toward your goals?</FormLabel>
                <Rating
                  name="progress-rating"
                  value={weeklyResponses?.progressRating || 3}
                  onChange={handleRatingChange('progressRating')}
                  max={5}
                  size="large"
                  sx={{ mt: 1 }}
                />
              </Box>
            </Grid>
          </Grid>
          
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSave}
            >
              Save Weekly Reflection
            </Button>
          </Box>
          
          {saveStatus && (
            <Typography 
              variant="subtitle1" 
              color="primary" 
              sx={{ mt: 2, textAlign: 'center' }}
            >
              {saveStatus}
            </Typography>
          )}
        </Paper>
        
        {savedResponses && (
          <Card sx={{ mt: 4 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Saved Weekly Reflection
              </Typography>
              <Divider sx={{ mb: 2 }} />
              
              <Typography variant="body1" gutterBottom>
                <strong>Weekly Highlight:</strong> {savedResponses.weeklyHighlight || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Grateful For:</strong> {savedResponses.gratefulFor || ''}
                {savedResponses.enteredInWorkday && 
                  <Typography component="span" color="success.main" sx={{ ml: 1 }}>
                    (Entered in Workday)
                  </Typography>
                }
              </Typography>
              
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                WTW: Values, Reflection, Recommitment
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Values:</strong> {savedResponses.wtw?.values || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Reflection on In-contingency:</strong> {savedResponses.wtw?.reflectIncontingency || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Recommitment to Close Gap:</strong> {savedResponses.wtw?.recommitCloseGap || ''}
              </Typography>
              
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                DT2TED: Problem, Shift, Action
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Problem:</strong> {savedResponses.dt2ted?.problem || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Shift:</strong> {savedResponses.dt2ted?.shift || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Action:</strong> {savedResponses.dt2ted?.action || ''}
              </Typography>
              
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                What Else?
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Wins:</strong> {savedResponses.wins || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Challenges:</strong> {savedResponses.challenges || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Lessons:</strong> {savedResponses.lessons || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Next Week Focus:</strong> {savedResponses.nextWeekFocus || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Gratitude:</strong> {savedResponses.gratitude || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Wellbeing Rating:</strong> {savedResponses.wellbeingRating}/5
              </Typography>
              
              <Typography variant="body1">
                <strong>Progress Rating:</strong> {savedResponses.progressRating}/5
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
    </Container>
  );
}

export default WeeklyReflection;
