import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Paper, 
  Tabs,
  Tab,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Rating,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Chip,
  LinearProgress,
  Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import InsightsIcon from '@mui/icons-material/Insights';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { useAuth } from '../contexts/AuthContext';
import { 
  getAllResponseDates,
  loadDailyResponses
} from '../utils/responseUtils';
import {
  getAllWeeklyResponseWeeks,
  loadWeeklyResponses,
  formatWeekDisplay
} from '../utils/weeklyUtils';
import {
  extractThemes,
  calculateRatingTrends,
  analyzeRecalibration,
  formatThemeData
} from '../utils/themeUtils';

function PastResponses() {
  const { currentUser } = useAuth();
  const [tabValue, setTabValue] = useState(0);
  const [dailyDates, setDailyDates] = useState([]);
  const [weeklyDates, setWeeklyDates] = useState([]);
  const [expandedItem, setExpandedItem] = useState(null);
  const [responses, setResponses] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingItem, setLoadingItem] = useState(null);
  const [error, setError] = useState(null);
  const [allDailyResponses, setAllDailyResponses] = useState([]);
  const [allWeeklyResponses, setAllWeeklyResponses] = useState([]);
  const [loadingAllResponses, setLoadingAllResponses] = useState(false);

  // Load all response dates
  useEffect(() => {
    async function fetchResponseDates() {
      if (!currentUser) {
        setLoading(false);
        return;
      }
      
      try {
        // Get all daily response dates
        const dates = await getAllResponseDates(currentUser.email);
        
        // If no dates returned, use mock data for testing
        if (dates.length === 0) {
          console.log('No response dates found, using mock data for testing');
          const mockDates = [
            '2025-03-11',
            '2025-03-10',
            '2025-03-09'
          ];
          setDailyDates(mockDates);
        } else {
          setDailyDates(dates);
        }
        
        // Get all weekly response keys
        const weeks = [...new Set(await getAllWeeklyResponseWeeks(currentUser.email))];
        
        // If no weeks returned, use mock data for testing
        if (weeks.length === 0) {
          console.log('No weekly response weeks found, using mock data for testing');
          const mockWeeks = [
            '2025-W10',
            '2025-W09'
          ];
          setWeeklyDates(mockWeeks);
        } else {
          setWeeklyDates(weeks);
        }
        
        setError(null);
      } catch (error) {
        console.error("Error loading response dates:", error);
        
        // Use mock data if there's an error
        console.log('Error loading response dates, using mock data for testing');
        setDailyDates(['2025-03-11', '2025-03-10', '2025-03-09']);
        setWeeklyDates(['2025-W10', '2025-W09']);
        
        // Don't show error to user since we're using mock data
        setError(null);
      } finally {
        setLoading(false);
      }
    }
    
    fetchResponseDates();
  }, [currentUser]);

  // Load all responses for theme analysis when tab changes to Themes & Insights
  useEffect(() => {
    async function fetchAllResponses() {
      if (!currentUser || tabValue !== 2 || dailyDates.length === 0) return;
      
      setLoadingAllResponses(true);
      
      try {
        // Load all daily responses
        const dailyPromises = dailyDates.map(async (date) => {
          const response = await loadDailyResponses(currentUser.email, date);
          return {
            date,
            responses: response
          };
        });
        
        // Load all weekly responses
        const weeklyPromises = weeklyDates.map(async (week) => {
          const response = await loadWeeklyResponses(currentUser.email, week);
          return {
            week,
            responses: response
          };
        });
        
        const dailyResults = await Promise.all(dailyPromises);
        const weeklyResults = await Promise.all(weeklyPromises);
        
        setAllDailyResponses(dailyResults.filter(r => r.responses));
        setAllWeeklyResponses(weeklyResults.filter(r => r.responses));
      } catch (error) {
        console.error("Error loading all responses for theme analysis:", error);
      } finally {
        setLoadingAllResponses(false);
      }
    }
    
    fetchAllResponses();
  }, [currentUser, tabValue, dailyDates, weeklyDates]);

  // Load response when expanded item changes
  useEffect(() => {
    async function fetchResponseData() {
      if (!currentUser || !expandedItem) return;
      
      const [type, key] = expandedItem.split(':');
      setLoadingItem(expandedItem);
      
      try {
        if (type === 'daily') {
          const dailyResponse = await loadDailyResponses(currentUser.email, key);
          if (dailyResponse) {
            setResponses(prev => ({
              ...prev,
              [expandedItem]: dailyResponse
            }));
          }
        } else if (type === 'weekly') {
          const weeklyResponse = await loadWeeklyResponses(currentUser.email, key);
          if (weeklyResponse) {
            setResponses(prev => ({
              ...prev,
              [expandedItem]: weeklyResponse
            }));
          }
        }
      } catch (error) {
        console.error(`Error loading ${type} response:`, error);
      } finally {
        setLoadingItem(null);
      }
    }
    
    fetchResponseData();
  }, [currentUser, expandedItem]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setExpandedItem(null);
  };

  // Extract themes from daily responses
  const dailyThemes = useMemo(() => {
    if (allDailyResponses.length === 0) return { commonWords: {}, topThemes: [] };
    
    // Extract responses data
    const responseData = allDailyResponses.map(item => item.responses);
    
    // Fields to analyze for themes
    const fields = ['highlight', 'gratitude', 'proud', 'learned', 'create'];
    
    return extractThemes(responseData, fields);
  }, [allDailyResponses]);

  // Extract themes from weekly responses
  const weeklyThemes = useMemo(() => {
    if (allWeeklyResponses.length === 0) return { commonWords: {}, topThemes: [] };
    
    // Extract responses data
    const responseData = allWeeklyResponses.map(item => item.responses);
    
    // Fields to analyze for themes
    const fields = ['weeklyHighlight', 'gratefulFor', 'wins', 'challenges', 'lessons', 'nextWeekFocus', 'gratitude'];
    
    return extractThemes(responseData, fields);
  }, [allWeeklyResponses]);

  // Calculate rating trends
  const ratingTrends = useMemo(() => {
    return calculateRatingTrends(allWeeklyResponses);
  }, [allWeeklyResponses]);

  // Analyze recalibration patterns
  const recalibrationStats = useMemo(() => {
    return analyzeRecalibration(allDailyResponses);
  }, [allDailyResponses]);

  // Toggle expanded item
  const toggleExpand = useCallback((type, key) => {
    const itemKey = `${type}:${key}`;
    setExpandedItem(prevItem => prevItem === itemKey ? null : itemKey);
  }, []);

  // Format date for display
  const formatDateForDisplay = useCallback((dateString) => {
    // Parse the date string manually to avoid timezone issues
    const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
    
    // Create date with local timezone (months are 0-indexed in JavaScript Date)
    const date = new Date(year, month - 1, day);
    
    return date.toLocaleDateString('en-US', { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  }, []);

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Past Responses
          </Typography>
          <Paper sx={{ p: 3, mt: 3, textAlign: 'center' }}>
            <Typography color="error">{error}</Typography>
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Past Responses
        </Typography>
        
        <Paper sx={{ mt: 3 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Daily Reflections" />
            <Tab label="Weekly Reflections" />
            <Tab label="Themes & Insights" />
          </Tabs>
          
          <Box sx={{ p: 3 }}>
            {tabValue === 0 && (
              <>
                <Typography variant="h6" gutterBottom>
                  Past Daily Reflections
                </Typography>
                
                {dailyDates.length > 0 ? (
                  <List>
                    {dailyDates.map((date) => {
                      const itemKey = `daily:${date}`;
                      const isExpanded = expandedItem === itemKey;
                      const dateResponses = responses[itemKey];
                      const isLoading = loadingItem === itemKey;
                      
                      return (
                        <React.Fragment key={date}>
                          <ListItem 
                            button 
                            onClick={() => toggleExpand('daily', date)}
                            sx={{ 
                              bgcolor: isExpanded ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                              borderRadius: 1
                            }}
                          >
                            <ListItemText 
                              primary={formatDateForDisplay(date)} 
                            />
                            <IconButton edge="end">
                              {isLoading ? (
                                <CircularProgress size={24} />
                              ) : (
                                isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                              )}
                            </IconButton>
                          </ListItem>
                          
                          {isExpanded && dateResponses && (
                            <Collapse in={true} timeout="auto" unmountOnExit>
                              <Box sx={{ p: 2, pl: 4, pr: 4 }}>
                                <Typography variant="body1" gutterBottom>
                                  <strong>Daily Highlight:</strong> {dateResponses.highlight || 'Not answered'}
                                </Typography>
                                
                                <Typography variant="body1" gutterBottom>
                                  <strong>Gratitude:</strong> {dateResponses.gratitude || 'Not answered'}
                                </Typography>
                                
                                <Typography variant="body1" gutterBottom>
                                  <strong>Proud of:</strong> {dateResponses.proud || 'Not answered'}
                                </Typography>
                                
                                <Typography variant="body1" gutterBottom>
                                  <strong>Learned:</strong> {dateResponses.learned || 'Not answered'}
                                </Typography>
                                
                                <Typography variant="body1" gutterBottom>
                                  <strong>Created:</strong> {dateResponses.create || 'Not answered'}
                                </Typography>
                                
                                <Typography variant="body1">
                                  <strong>Recalibrated:</strong> {dateResponses.recalibrated ? 'Yes' : 'No'}
                                </Typography>
                              </Box>
                            </Collapse>
                          )}
                          <Divider component="li" />
                        </React.Fragment>
                      );
                    })}
                  </List>
                ) : (
                  <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                    No daily reflections found.
                  </Typography>
                )}
              </>
            )}
            
            {tabValue === 1 && (
              <>
                <Typography variant="h6" gutterBottom>
                  Past Weekly Reflections
                </Typography>
                
                {weeklyDates.length > 0 ? (
                  <List>
                    {weeklyDates.map((weekKey) => {
                      const itemKey = `weekly:${weekKey}`;
                      const isExpanded = expandedItem === itemKey;
                      const weekResponses = responses[itemKey];
                      const isLoading = loadingItem === itemKey;
                      
                      return (
                        <React.Fragment key={weekKey}>
                          <ListItem 
                            button 
                            onClick={() => toggleExpand('weekly', weekKey)}
                            sx={{ 
                              bgcolor: isExpanded ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                              borderRadius: 1
                            }}
                          >
                            <ListItemText 
                              primary={formatWeekDisplay(weekKey)} 
                            />
                            <IconButton edge="end">
                              {isLoading ? (
                                <CircularProgress size={24} />
                              ) : (
                                isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                              )}
                            </IconButton>
                          </ListItem>
                          
                          {isExpanded && weekResponses && (
                            <Collapse in={true} timeout="auto" unmountOnExit>
                              <Box sx={{ p: 2, pl: 4, pr: 4 }}>
                                <Typography variant="body1" gutterBottom>
                                  <strong>Weekly Highlight:</strong> {weekResponses.weeklyHighlight || 'Not answered'}
                                </Typography>
                                
                                <Typography variant="body1" gutterBottom>
                                  <strong>Grateful For:</strong> {weekResponses.gratefulFor || 'Not answered'}
                                </Typography>
                                
                                <Typography variant="body1" gutterBottom>
                                  <strong>Wins:</strong> {weekResponses.wins || 'Not answered'}
                                </Typography>
                                
                                <Typography variant="body1" gutterBottom>
                                  <strong>Challenges:</strong> {weekResponses.challenges || 'Not answered'}
                                </Typography>
                                
                                <Typography variant="body1" gutterBottom>
                                  <strong>Lessons:</strong> {weekResponses.lessons || 'Not answered'}
                                </Typography>
                                
                                <Typography variant="body1" gutterBottom>
                                  <strong>Next Week Focus:</strong> {weekResponses.nextWeekFocus || 'Not answered'}
                                </Typography>
                                
                                <Typography variant="body1" gutterBottom>
                                  <strong>Gratitude:</strong> {weekResponses.gratitude || 'Not answered'}
                                </Typography>
                                
                                <Box sx={{ mt: 1, mb: 1 }}>
                                  <Typography variant="body1" component="div" gutterBottom>
                                    <strong>Wellbeing Rating:</strong> 
                                    <Rating 
                                      value={weekResponses.wellbeingRating || 0} 
                                      readOnly 
                                      size="small"
                                      sx={{ ml: 1, verticalAlign: 'middle' }}
                                    />
                                  </Typography>
                                </Box>
                                
                                <Box sx={{ mt: 1, mb: 1 }}>
                                  <Typography variant="body1" component="div">
                                    <strong>Progress Rating:</strong> 
                                    <Rating 
                                      value={weekResponses.progressRating || 0} 
                                      readOnly 
                                      size="small"
                                      sx={{ ml: 1, verticalAlign: 'middle' }}
                                    />
                                  </Typography>
                                </Box>
                              </Box>
                            </Collapse>
                          )}
                          <Divider component="li" />
                        </React.Fragment>
                      );
                    })}
                  </List>
                ) : (
                  <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                    No weekly reflections found.
                  </Typography>
                )}
              </>
            )}
            {tabValue === 2 && (
              <>
                <Typography variant="h6" gutterBottom>
                  Themes & Insights
                </Typography>
                
                {loadingAllResponses ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Grid container spacing={3}>
                    {/* Rating Trends Card */}
                    <Grid item xs={12} md={6}>
                      <Card variant="outlined" sx={{ height: '100%' }}>
                        <CardContent>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <TrendingUpIcon color="primary" sx={{ mr: 1 }} />
                            <Typography variant="h6">Rating Trends</Typography>
                          </Box>
                          
                          {ratingTrends.wellbeing.length > 0 ? (
                            <>
                              <Typography variant="subtitle1" gutterBottom>
                                Wellbeing Rating Average: {ratingTrends.averages.wellbeing}
                              </Typography>
                              <Box sx={{ mb: 2 }}>
                                <Rating 
                                  value={ratingTrends.averages.wellbeing} 
                                  precision={0.1}
                                  readOnly 
                                />
                              </Box>
                              
                              <Typography variant="subtitle1" gutterBottom>
                                Progress Rating Average: {ratingTrends.averages.progress}
                              </Typography>
                              <Box>
                                <Rating 
                                  value={ratingTrends.averages.progress} 
                                  precision={0.1}
                                  readOnly 
                                />
                              </Box>
                            </>
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              Not enough rating data available yet.
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    
                    {/* Recalibration Stats Card */}
                    <Grid item xs={12} md={6}>
                      <Card variant="outlined" sx={{ height: '100%' }}>
                        <CardContent>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <AutoAwesomeIcon color="primary" sx={{ mr: 1 }} />
                            <Typography variant="h6">Recalibration Stats</Typography>
                          </Box>
                          
                          {allDailyResponses.length > 0 ? (
                            <>
                              <Typography variant="body1" gutterBottom>
                                Days recalibrated: {recalibrationStats.recalibrated} of {recalibrationStats.recalibrated + recalibrationStats.notRecalibrated} days
                              </Typography>
                              
                              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                <Box sx={{ width: '100%', mr: 1 }}>
                                  <LinearProgress 
                                    variant="determinate" 
                                    value={recalibrationStats.percentage} 
                                    sx={{ height: 10, borderRadius: 5 }}
                                  />
                                </Box>
                                <Box sx={{ minWidth: 35 }}>
                                  <Typography variant="body2" color="text.secondary">
                                    {recalibrationStats.percentage}%
                                  </Typography>
                                </Box>
                              </Box>
                            </>
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              Not enough recalibration data available yet.
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    
                    {/* Daily Themes Card */}
                    <Grid item xs={12} md={6}>
                      <Card variant="outlined">
                        <CardContent>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <InsightsIcon color="primary" sx={{ mr: 1 }} />
                            <Typography variant="h6">Daily Reflection Themes</Typography>
                          </Box>
                          
                          {dailyThemes.topThemes.length > 0 ? (
                            <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                              {dailyThemes.topThemes.map((theme) => (
                                <Chip 
                                  key={theme.word}
                                  label={`${theme.word} (${theme.count})`}
                                  color="primary"
                                  variant={theme.count > 2 ? "filled" : "outlined"}
                                  size="medium"
                                  sx={{ m: 0.5 }}
                                />
                              ))}
                            </Stack>
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              Not enough data to extract themes yet.
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    
                    {/* Weekly Themes Card */}
                    <Grid item xs={12} md={6}>
                      <Card variant="outlined">
                        <CardContent>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <FormatQuoteIcon color="primary" sx={{ mr: 1 }} />
                            <Typography variant="h6">Weekly Reflection Themes</Typography>
                          </Box>
                          
                          {weeklyThemes.topThemes.length > 0 ? (
                            <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                              {weeklyThemes.topThemes.map((theme) => (
                                <Chip 
                                  key={theme.word}
                                  label={`${theme.word} (${theme.count})`}
                                  color="secondary"
                                  variant={theme.count > 1 ? "filled" : "outlined"}
                                  size="medium"
                                  sx={{ m: 0.5 }}
                                />
                              ))}
                            </Stack>
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              Not enough data to extract themes yet.
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    
                    {/* Insights Summary */}
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Insights Summary
                          </Typography>
                          
                          {allDailyResponses.length > 0 || allWeeklyResponses.length > 0 ? (
                            <Typography variant="body1">
                              Based on your {allDailyResponses.length} daily and {allWeeklyResponses.length} weekly reflections, 
                              you've shown consistent themes around 
                              {dailyThemes.topThemes.length > 0 ? 
                                ` ${dailyThemes.topThemes.slice(0, 3).map(t => t.word).join(', ')}` : 
                                ' your reflections'
                              }. 
                              {ratingTrends.averages.wellbeing > 0 ? 
                                ` Your average wellbeing rating is ${ratingTrends.averages.wellbeing} and progress rating is ${ratingTrends.averages.progress}.` : 
                                ''
                              }
                              {recalibrationStats.percentage > 0 ?
                                ` You've recalibrated on ${recalibrationStats.percentage}% of your reflection days.` :
                                ''
                              }
                            </Typography>
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              Complete more reflections to see personalized insights.
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default PastResponses;
