/**
 * Utility functions for handling daily and weekly responses
 */
import { 
  collection, 
  doc, 
  setDoc, 
  getDoc, 
  query, 
  where, 
  getDocs, 
  orderBy 
} from 'firebase/firestore';
import { db } from '../firebase/config';

/**
 * Format date as YYYY-MM-DD in local timezone
 * @param {Date} date - The date to format
 * @returns {string} Formatted date string
 */
export const formatDateString = (date) => {
  // Use local timezone instead of UTC
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
};

/**
 * Get today's date as YYYY-MM-DD
 * @returns {string} Today's date as YYYY-MM-DD
 */
export const getTodayDateString = () => {
  return formatDateString(new Date());
};

/**
 * Save daily responses to Firestore
 * @param {string} email - User's email
 * @param {string} dateString - Date in YYYY-MM-DD format
 * @param {Object} responses - The responses to save
 * @returns {Promise} Promise that resolves when the data is saved
 */
export const saveDailyResponses = async (email, dateString, responses) => {
  if (!email) return;
  
  const userEmail = email.replace(/\./g, ','); // Replace dots with commas for Firestore path
  const docRef = doc(db, 'dailyResponses', `${userEmail}_${dateString}`);
  
  await setDoc(docRef, {
    email: userEmail,
    date: dateString,
    responses,
    timestamp: new Date()
  });
};

/**
 * Load daily responses from Firestore
 * @param {string} email - User's email
 * @param {string} dateString - Date in YYYY-MM-DD format
 * @returns {Promise<Object|null>} The responses or null if not found
 */
export const loadDailyResponses = async (email, dateString) => {
  if (!email) return null;
  
  try {
    const userEmail = email.replace(/\./g, ',');
    const docRef = doc(db, 'dailyResponses', `${userEmail}_${dateString}`);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      return docSnap.data().responses;
    }
    
    // Try to load from localStorage as fallback during transition
    const localStorageData = loadDailyResponsesFromLocalStorage(email, dateString);
    if (localStorageData) {
      return localStorageData;
    }
    
    // Return empty responses instead of mock data
    console.log(`No daily response found for ${dateString}, returning empty responses`);
    return createEmptyDailyResponses();
  } catch (error) {
    console.error('Error loading daily responses:', error);
    
    // Return empty responses in case of error
    return createEmptyDailyResponses();
  }
};

/**
 * Load daily responses from localStorage (for backward compatibility)
 * @param {string} email - User's email
 * @param {string} dateString - Date in YYYY-MM-DD format
 * @returns {Object|null} The responses or null if not found
 */
const loadDailyResponsesFromLocalStorage = (email, dateString) => {
  if (!email) return null;
  
  const storageKey = `vitalume-daily-${email}-${dateString}`;
  const savedData = localStorage.getItem(storageKey);
  
  if (savedData) {
    const parsedData = JSON.parse(savedData);
    // Migrate to Firestore
    saveDailyResponses(email, dateString, parsedData);
    return parsedData;
  }
  
  return null;
};

/**
 * Get all dates with saved responses for a user
 * @param {string} email - User's email
 * @returns {Promise<Array>} Promise that resolves to an array of date strings (YYYY-MM-DD)
 */
export const getAllResponseDates = async (email) => {
  if (!email) return [];
  
  try {
    const userEmail = email.replace(/\./g, ',');
    console.log(`Getting response dates for user: ${userEmail}`);
    
    // First try to query with the 'email' field
    const q = query(
      collection(db, 'dailyResponses'),
      where('email', '==', userEmail),
      orderBy('date', 'desc')
    );
    
    console.log('Query created, fetching documents...');
    const querySnapshot = await getDocs(q);
    console.log(`Query returned ${querySnapshot.size} documents`);
    
    const dates = [];
    
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      console.log(`Document data:`, data);
      if (data && data.date) {
        dates.push(data.date);
      }
    });
    
    // If no results, try checking if there are any documents in the collection
    if (dates.length === 0) {
      console.log('No results found with email filter, checking collection...');
      const allDocsQuery = query(collection(db, 'dailyResponses'), orderBy('date', 'desc'));
      const allDocsSnapshot = await getDocs(allDocsQuery);
      
      console.log(`Collection has ${allDocsSnapshot.size} total documents`);
      
      // If there are documents but none for this user, it might be a data structure issue
      if (allDocsSnapshot.size > 0) {
        console.log('Documents exist but none match the user email. Sample document:');
        if (allDocsSnapshot.docs[0]) {
          console.log(allDocsSnapshot.docs[0].id, allDocsSnapshot.docs[0].data());
        }
      }
      
      // As a fallback, try to get documents by ID pattern
      console.log('Trying fallback method with document ID pattern...');
      const fallbackQuery = query(collection(db, 'dailyResponses'));
      const fallbackSnapshot = await getDocs(fallbackQuery);
      
      fallbackSnapshot.forEach((doc) => {
        // Check if the document ID starts with the user email
        if (doc.id.startsWith(userEmail)) {
          const data = doc.data();
          console.log(`Found document by ID pattern:`, doc.id, data);
          if (data && data.date) {
            dates.push(data.date);
          }
        }
      });
    }
    
    console.log(`Returning ${dates.length} dates`);
    return dates;
  } catch (error) {
    console.error('Error in getAllResponseDates:', error);
    // Add mock data for testing if in development
    if (process.env.NODE_ENV === 'development') {
      console.log('Adding mock data for testing in development mode');
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const twoDaysAgo = new Date(today);
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
      
      return [
        formatDateString(today),
        formatDateString(yesterday),
        formatDateString(twoDaysAgo)
      ];
    }
    
    // Rethrow the error with more context
    throw new Error(`Failed to get response dates: ${error.message}`);
  }
};

/**
 * Create empty daily responses object
 * @returns {Object} Empty responses object
 */
export const createEmptyDailyResponses = () => {
  return {
    highlight: '',
    gratitude: '',
    proud: '',
    learned: '',
    create: '',
    recalibrated: false
  };
};

// Backward compatibility function for transition period
// This will try to load from localStorage first, then Firestore
export const loadDailyResponsesWithFallback = async (email, dateString) => {
  // Try Firestore first
  const firestoreData = await loadDailyResponses(email, dateString);
  if (firestoreData) return firestoreData;
  
  // Fall back to localStorage if not found in Firestore
  const storageKey = `vitalume-daily-${email}-${dateString}`;
  const savedData = localStorage.getItem(storageKey);
  
  if (savedData) {
    const parsedData = JSON.parse(savedData);
    // Migrate to Firestore
    await saveDailyResponses(email, dateString, parsedData);
    return parsedData;
  }
  
  return null;
};
