import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';

const AnimatedBackground = () => {
  const canvasRef = useRef(null);
  const theme = useTheme();
  
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d', { alpha: false });
    let animationFrameId;
    
    // Set canvas to full window size
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    
    window.addEventListener('resize', handleResize);
    handleResize();
    
    // Create color blobs with colors from our theme
    const blobs = [];
    const colors = [
      'rgba(14, 30, 51, 0.5)',     // Dark navy blue (primary)
      'rgba(78, 205, 196, 0.6)',   // Teal/turquoise (secondary)
      'rgba(255, 157, 92, 0.6)',   // Orange (accent1)
      'rgba(255, 245, 225, 0.5)',  // Cream/white (accent2)
      'rgba(78, 205, 196, 0.3)',   // Lighter teal
      'rgba(255, 157, 92, 0.3)'    // Lighter orange
    ];
    
    // Initialize larger blobs with more dramatic animation parameters
    for (let i = 0; i < 10; i++) {
      blobs.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 350 + 200,
        color: colors[Math.floor(Math.random() * colors.length)],
        vx: Math.random() * 4 - 2,
        vy: Math.random() * 4 - 2,
        pulseSpeed: Math.random() * 0.05 + 0.03,
        pulseDirection: 1,
        pulseAmount: 0,
        maxPulse: Math.random() * 0.5 + 0.3,
        opacity: Math.random() * 0.7 + 0.3,
        fadeSpeed: Math.random() * 0.03 + 0.015,
        fadeDirection: Math.random() > 0.5 ? 1 : -1
      });
    }
    
    let isPaused = false;

    const animate = () => {
      if (isPaused) return;

      ctx.fillStyle = '#f8f9fa';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      blobs.forEach((blob) => {
        blob.pulseAmount += blob.pulseSpeed * blob.pulseDirection;
        if (blob.pulseAmount > blob.maxPulse || blob.pulseAmount < 0) {
          blob.pulseDirection *= -1;
        }
        
        blob.opacity += blob.fadeSpeed * blob.fadeDirection;
        if (blob.opacity > 0.9) {
          blob.opacity = 0.9;
          blob.fadeDirection = -1;
        } else if (blob.opacity < 0.1) {
          blob.opacity = 0.1;
          blob.fadeDirection = 1;
        }
        
        const pulsingRadius = blob.radius * (1 + blob.pulseAmount);
        const baseColor = blob.color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)/);
        const r = baseColor ? baseColor[1] : 100;
        const g = baseColor ? baseColor[2] : 150;
        const b = baseColor ? baseColor[3] : 255;
        const colorWithOpacity = `rgba(${r}, ${g}, ${b}, ${blob.opacity})`;
        
        const gradient = ctx.createRadialGradient(
          blob.x, blob.y, 0,
          blob.x, blob.y, pulsingRadius
        );
        gradient.addColorStop(0, colorWithOpacity);
        gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
        
        ctx.beginPath();
        ctx.arc(blob.x, blob.y, pulsingRadius, 0, Math.PI * 2);
        ctx.fillStyle = gradient;
        ctx.fill();
        
        if (Math.random() < 0.05) {
          blob.vx += (Math.random() * 2 - 1);
          blob.vy += (Math.random() * 2 - 1);
        }
        
        blob.x += blob.vx;
        blob.y += blob.vy;
        
        if (blob.x < 0 || blob.x > canvas.width) {
          blob.vx = -blob.vx * 1.2;
          blob.x = blob.x < 0 ? 0 : canvas.width;
        }
        if (blob.y < 0 || blob.y > canvas.height) {
          blob.vy = -blob.vy * 1.2;
          blob.y = blob.y < 0 ? 0 : canvas.height;
        }
        
        blob.vx = Math.max(-5, Math.min(5, blob.vx));
        blob.vy = Math.max(-5, Math.min(5, blob.vy));
        
        if (Math.abs(blob.vx) < 0.5) blob.vx += (Math.random() * 1 - 0.5);
        if (Math.abs(blob.vy) < 0.5) blob.vy += (Math.random() * 1 - 0.5);
      });
      
      ctx.globalAlpha = 0.9;
      ctx.filter = 'blur(40px)';
      
      animationFrameId = window.requestAnimationFrame(animate);
    };
    
    animate();
    
    // Function to pause the animation
    const pauseAnimation = () => {
      if (!isPaused) {
        isPaused = true;
        console.log('Animation paused');
        window.cancelAnimationFrame(animationFrameId);
      }
    };

    // Function to resume the animation
    const resumeAnimation = () => {
      if (isPaused) {
        isPaused = false;
        console.log('Animation resumed');
        animate();
      }
    };

    // Add event listeners for pause and resume events
    window.addEventListener('pauseAnimation', pauseAnimation);
    window.addEventListener('resumeAnimation', resumeAnimation);
    
    // Cleanup function
    return () => {
      window.cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('pauseAnimation', pauseAnimation);
      window.removeEventListener('resumeAnimation', resumeAnimation);
    };
  }, []);
  
  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        pointerEvents: 'none'
      }}
    />
  );
};

export default AnimatedBackground;
