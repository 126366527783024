// Helper functions for user profile data
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

/**
 * Save profile data to Firestore
 * @param {string} email - User's email
 * @param {Object} data - Profile data to save
 * @returns {Promise} Promise that resolves when the data is saved
 */
export const saveProfileData = async (email, data) => {
  if (!email) return;
  
  const userEmail = email.replace(/\./g, ','); // Replace dots with commas for Firestore path
  const docRef = doc(db, 'userProfiles', userEmail);
  
  await setDoc(docRef, {
    email: userEmail,
    profileData: data,
    updatedAt: new Date()
  });
};

/**
 * Load profile data from Firestore
 * @param {string} email - User's email
 * @returns {Promise<Object|null>} The profile data or null if not found
 */
export const loadProfileData = async (email) => {
  if (!email) return null;
  
  const userEmail = email.replace(/\./g, ',');
  const docRef = doc(db, 'userProfiles', userEmail);
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists()) {
    return docSnap.data().profileData;
  }
  
  // Try to load from localStorage as fallback during transition
  return loadProfileDataFromLocalStorage(email);
};

/**
 * Get user profile picture
 * @param {string} email - User's email
 * @returns {Promise<string|null>} The profile picture URL or a default avatar if not found
 */
export const getUserProfilePicture = async (email) => {
  if (!email) return null;
  
  try {
    const profileData = await loadProfileData(email);
    
    // If profile data exists and has a profile picture, return it
    if (profileData?.profilePicture) {
      return profileData.profilePicture;
    }
    
    // Otherwise, return a default avatar based on the user's email
    // This ensures we always have something to display
    const defaultAvatar = `https://ui-avatars.com/api/?name=${encodeURIComponent(email.split('@')[0])}&background=random&color=fff&size=128`;
    console.log(`No profile picture found for ${email}, using default avatar: ${defaultAvatar}`);
    return defaultAvatar;
  } catch (error) {
    console.error('Error getting profile picture:', error);
    // Return a generic default avatar in case of error
    return 'https://ui-avatars.com/api/?name=User&background=0D8ABC&color=fff&size=128';
  }
};

// Backward compatibility functions for transition period
const getProfileStorageKey = (email) => {
  return `vitalume-profile-${email}`;
};

const loadProfileDataFromLocalStorage = (email) => {
  if (!email) return null;
  
  const storageKey = getProfileStorageKey(email);
  const savedData = localStorage.getItem(storageKey);
  
  if (savedData) {
    const parsedData = JSON.parse(savedData);
    // Migrate to Firestore
    saveProfileData(email, parsedData);
    return parsedData;
  }
  
  return null;
};
