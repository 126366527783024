import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Paper,
  Grid,
  Divider,
  Card,
  CardContent,
  Avatar,
  IconButton
} from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useAuth } from '../contexts/AuthContext';
import { saveProfileData, loadProfileData } from '../utils/profileUtils';

// Create empty profile data
const createEmptyProfileData = () => {
  return {
    profilePicture: '',
    goals: {
      shortTerm: '',
      mediumTerm: '',
      longTerm: ''
    },
    coreValues: '',
    purposeStatement: ''
  };
};

function UserProfile() {
  const { currentUser } = useAuth();
  const [profileData, setProfileData] = useState(createEmptyProfileData());
  const [savedData, setSavedData] = useState(null);
  const [saveStatus, setSaveStatus] = useState('');
  const [loading, setLoading] = useState(true);

  // Load saved profile data
  useEffect(() => {
    async function fetchProfileData() {
      if (!currentUser) {
        setLoading(false);
        return;
      }
      
      try {
        const userData = await loadProfileData(currentUser.email);
        if (userData) {
          setProfileData(userData);
          setSavedData(userData);
        } else {
          setProfileData(createEmptyProfileData());
          setSavedData(null);
        }
      } catch (error) {
        console.error("Error loading profile data:", error);
      } finally {
        setLoading(false);
      }
    }
    
    fetchProfileData();
  }, [currentUser]);

  // Handle text input changes for simple fields
  const handleInputChange = (field) => (event) => {
    setProfileData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  // Handle text input changes for nested fields
  const handleNestedInputChange = (parent, field) => (event) => {
    setProfileData(prev => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [field]: event.target.value
      }
    }));
  };

  // Save profile data
  const handleSave = async () => {
    if (!currentUser) return;
    
    try {
      setSaveStatus('Saving...');
      await saveProfileData(currentUser.email, profileData);
      setSavedData({...profileData});
      setSaveStatus('Profile data saved successfully!');
    } catch (error) {
      console.error("Error saving profile data:", error);
      setSaveStatus('Error saving profile data. Please try again.');
    }
    
    // Clear save status after 3 seconds
    setTimeout(() => {
      setSaveStatus('');
    }, 3000);
  };

  // Handle profile picture upload
  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileData(prev => ({
          ...prev,
          profilePicture: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
          <Typography variant="h6">Loading profile data...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Your Profile
        </Typography>
        
        <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <Box sx={{ position: 'relative' }}>
              <Avatar 
                src={profileData?.profilePicture || ''} 
                sx={{ 
                  width: 120, 
                  height: 120,
                  border: '2px solid #4ECDC4'
                }}
              />
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="profile-picture-upload"
                type="file"
                onChange={handleProfilePictureChange}
              />
              <label htmlFor="profile-picture-upload">
                <IconButton 
                  component="span"
                  sx={{ 
                    position: 'absolute', 
                    bottom: 0, 
                    right: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    }
                  }}
                >
                  <PhotoCameraIcon />
                </IconButton>
              </label>
            </Box>
          </Box>
          <Typography variant="h5" gutterBottom>
            Goals
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Short-term Goals (Next 3 months)"
                variant="outlined"
                value={profileData?.goals?.shortTerm || ''}
                onChange={handleNestedInputChange('goals', 'shortTerm')}
                multiline
                rows={3}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Medium-term Goals (By Year End)"
                variant="outlined"
                value={profileData?.goals?.mediumTerm || ''}
                onChange={handleNestedInputChange('goals', 'mediumTerm')}
                multiline
                rows={3}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Long-term Goals (3-5 years)"
                variant="outlined"
                value={profileData?.goals?.longTerm || ''}
                onChange={handleNestedInputChange('goals', 'longTerm')}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
          
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Core Values
          </Typography>
          
          <TextField
            fullWidth
            label="What are your core values?"
            variant="outlined"
            value={profileData?.coreValues || ''}
            onChange={handleInputChange('coreValues')}
            multiline
            rows={4}
            placeholder="List your core values that guide your decisions and actions"
          />
          
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Purpose Statement
          </Typography>
          
          <TextField
            fullWidth
            label="What is your purpose statement?"
            variant="outlined"
            value={profileData?.purposeStatement || ''}
            onChange={handleInputChange('purposeStatement')}
            multiline
            rows={4}
            placeholder="Write a statement that defines your purpose and what gives your life meaning"
          />
          
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSave}
              size="large"
            >
              Save Profile
            </Button>
          </Box>
          
          {saveStatus && (
            <Typography 
              variant="subtitle1" 
              color="primary" 
              sx={{ mt: 2, textAlign: 'center' }}
            >
              {saveStatus}
            </Typography>
          )}
        </Paper>
        
        {savedData && (
          <Card sx={{ mt: 4 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Saved Profile Data
              </Typography>
              <Divider sx={{ mb: 2 }} />
              
              {savedData.profilePicture && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                  <Avatar 
                    src={savedData.profilePicture} 
                    sx={{ 
                      width: 80, 
                      height: 80,
                      border: '2px solid #4ECDC4'
                    }}
                  />
                </Box>
              )}
              
              <Typography variant="h6" gutterBottom>
                Goals
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Short-term:</strong> {savedData.goals?.shortTerm || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Medium-term:</strong> {savedData.goals?.mediumTerm || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Long-term:</strong> {savedData.goals?.longTerm || ''}
              </Typography>
              
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Core Values
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                {savedData.coreValues || ''}
              </Typography>
              
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Purpose Statement
              </Typography>
              
              <Typography variant="body1">
                {savedData.purposeStatement || ''}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
    </Container>
  );
}

export default UserProfile;
