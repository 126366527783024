import React from 'react';
import { Box } from '@mui/material';

function Logo({ height = 40, showText = false }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <img 
        src={require('../assets/new-logo.png')} 
        alt="Vital Reflection Logo" 
        style={{ 
          height: height, 
          width: 'auto', 
          marginRight: showText ? '8px' : '0' 
        }} 
      />
      
      {showText && (
        <Box 
          component="span" 
          sx={{ 
            fontWeight: 'bold',
            fontSize: height * 0.35,
            letterSpacing: '0.5px',
            position: 'relative',
            top: '10%'
          }}
        >
          Vital Reflection
        </Box>
      )}
    </Box>
  );
}

export default Logo;
