/**
 * Utility functions for handling weekly reflections
 */
import { 
  doc, 
  setDoc, 
  getDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy 
} from 'firebase/firestore';
import { db } from '../firebase/config';

/**
 * Helper function to get the current week number and year
 * @returns {string} Current week key in format YYYY-WXX
 */
export const getCurrentWeekKey = () => {
  const now = new Date();
  const start = new Date(now.getFullYear(), 0, 1);
  const diff = now - start;
  const oneWeek = 604800000;
  const week = Math.floor(diff / oneWeek) + 1;
  return `${now.getFullYear()}-W${week.toString().padStart(2, '0')}`;
};

/**
 * Helper function to create empty weekly responses
 * @returns {Object} Empty weekly responses object
 */
export const createEmptyWeeklyResponses = () => {
  return {
    weeklyHighlight: '',
    gratefulFor: '',
    enteredInWorkday: false,
    wtw: {
      values: '',
      reflectIncontingency: '',
      recommitCloseGap: ''
    },
    dt2ted: {
      problem: '',
      shift: '',
      action: ''
    },
    wins: '',
    challenges: '',
    lessons: '',
    nextWeekFocus: '',
    gratitude: '',
    wellbeingRating: 3,
    progressRating: 3
  };
};

/**
 * Save weekly responses to Firestore
 * @param {string} email - User's email
 * @param {string} weekKey - Week key in format YYYY-WXX
 * @param {Object} responses - The responses to save
 * @returns {Promise} Promise that resolves when the data is saved
 */
export const saveWeeklyResponses = async (email, weekKey, responses) => {
  if (!email) return;
  
  const userEmail = email.replace(/\./g, ','); // Replace dots with commas for Firestore path
  const docRef = doc(db, 'weeklyResponses', `${userEmail}_${weekKey}`);
  
  await setDoc(docRef, {
    email: userEmail,
    week: weekKey,
    responses,
    timestamp: new Date()
  });
};

/**
 * Load weekly responses from Firestore
 * @param {string} email - User's email
 * @param {string} weekKey - Week key in format YYYY-WXX
 * @returns {Promise<Object|null>} The responses or null if not found
 */
export const loadWeeklyResponses = async (email, weekKey) => {
  if (!email) return null;
  
  try {
    const userEmail = email.replace(/\./g, ',');
    const docRef = doc(db, 'weeklyResponses', `${userEmail}_${weekKey}`);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      return docSnap.data().responses;
    }
    
    // Try to load from localStorage as fallback during transition
    const localStorageData = loadWeeklyResponsesFromLocalStorage(email, weekKey);
    if (localStorageData) {
      return localStorageData;
    }
    
    // Return empty responses instead of mock data
    console.log(`No weekly response found for ${weekKey}, returning empty responses`);
    return createEmptyWeeklyResponses();
  } catch (error) {
    console.error('Error loading weekly responses:', error);
    
    // Return empty responses in case of error
    return createEmptyWeeklyResponses();
  }
};

/**
 * Helper function to get the calendar week from a week key
 * @param {string} weekKey - Week key in format YYYY-WXX
 * @returns {string} Calendar week in format YYYY-WXX
 */
export const getCalendarWeek = (weekKey) => {
  // Extract the year and week number
  const [year, weekNum] = weekKey.split('-W');
  
  // Return the calendar week (already in the right format)
  return `${year}-W${weekNum}`;
};

/**
 * Get all weeks with saved responses for a user
 * @param {string} email - User's email
 * @returns {Promise<Array>} Promise that resolves to an array of week keys (YYYY-WXX)
 */
export const getAllWeeklyResponseWeeks = async (email) => {
  if (!email) return [];
  
  try {
    const userEmail = email.replace(/\./g, ',');
    console.log(`Getting weekly response weeks for user: ${userEmail}`);
    
    // First try to query with the 'email' field
    const q = query(
      collection(db, 'weeklyResponses'),
      where('email', '==', userEmail),
      orderBy('week', 'desc')
    );
    
    console.log('Weekly query created, fetching documents...');
    const querySnapshot = await getDocs(q);
    console.log(`Weekly query returned ${querySnapshot.size} documents`);
    
    const weeks = [];
    const calendarWeeks = new Set(); // Track unique calendar weeks
    
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      console.log(`Weekly document data:`, data);
      if (data && data.week) {
        const calendarWeek = getCalendarWeek(data.week);
        
        // Only add if we haven't seen this calendar week before
        if (!calendarWeeks.has(calendarWeek)) {
          calendarWeeks.add(calendarWeek);
          weeks.push(data.week);
        }
      }
    });
    
    // If no results, try checking if there are any documents in the collection
    if (weeks.length === 0) {
      console.log('No weekly results found with email filter, checking collection...');
      const allDocsQuery = query(collection(db, 'weeklyResponses'), orderBy('week', 'desc'));
      const allDocsSnapshot = await getDocs(allDocsQuery);
      
      console.log(`Weekly collection has ${allDocsSnapshot.size} total documents`);
      
      // If there are documents but none for this user, it might be a data structure issue
      if (allDocsSnapshot.size > 0) {
        console.log('Weekly documents exist but none match the user email. Sample document:');
        if (allDocsSnapshot.docs[0]) {
          console.log(allDocsSnapshot.docs[0].id, allDocsSnapshot.docs[0].data());
        }
      }
      
      // As a fallback, try to get documents by ID pattern
      console.log('Trying fallback method with document ID pattern for weekly responses...');
      const fallbackQuery = query(collection(db, 'weeklyResponses'));
      const fallbackSnapshot = await getDocs(fallbackQuery);
      
      fallbackSnapshot.forEach((doc) => {
        // Check if the document ID starts with the user email
        if (doc.id.startsWith(userEmail)) {
          const data = doc.data();
          console.log(`Found weekly document by ID pattern:`, doc.id, data);
          if (data && data.week) {
            const calendarWeek = getCalendarWeek(data.week);
            
            // Only add if we haven't seen this calendar week before
            if (!calendarWeeks.has(calendarWeek)) {
              calendarWeeks.add(calendarWeek);
              weeks.push(data.week);
            }
          }
        }
      });
    }
    
    console.log(`Returning ${weeks.length} unique calendar weeks`);
    return weeks;
  } catch (error) {
    console.error('Error in getAllWeeklyResponseWeeks:', error);
    // Add mock data for testing if in development
    if (process.env.NODE_ENV === 'development') {
      console.log('Adding mock weekly data for testing in development mode');
      
      // Generate mock data with one entry per calendar week
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      
      // Get the current week number (1-52)
      const currentWeekNum = Math.ceil((((currentDate - new Date(currentYear, 0, 1)) / 86400000) + 1) / 7);
      
      // Create week keys for the current week and the previous two weeks
      const mockWeeks = [
        `${currentYear}-W${currentWeekNum.toString().padStart(2, '0')}`,
        `${currentYear}-W${(currentWeekNum - 1).toString().padStart(2, '0')}`,
        `${currentYear}-W${(currentWeekNum - 2).toString().padStart(2, '0')}`
      ];
      
      return mockWeeks;
    }
    
    // Rethrow the error with more context
    throw new Error(`Failed to get weekly response weeks: ${error.message}`);
  }
};

// Backward compatibility functions for transition period
const loadWeeklyResponsesFromLocalStorage = (email, weekKey) => {
  if (!email) return null;
  
  const storageKey = `vitalume-weekly-${email}-${weekKey}`;
  const savedData = localStorage.getItem(storageKey);
  
  if (savedData) {
    const parsedData = JSON.parse(savedData);
    // Migrate to Firestore
    saveWeeklyResponses(email, weekKey, parsedData);
    return parsedData;
  }
  
  return null;
};

/**
 * Format week for display as a date range
 * @param {string} weekKey - Week key in format YYYY-WXX
 * @returns {string} Formatted date range (e.g., "March 9th - 15th, 2025")
 */
export const formatWeekDisplay = (weekKey) => {
  const [yearStr, weekStr] = weekKey.split('-W');
  const year = parseInt(yearStr, 10);
  const weekNum = parseInt(weekStr, 10);
  
  // Calculate the first day of the year
  const firstDayOfYear = new Date(year, 0, 1);
  
  // Calculate the first day of the week (Sunday)
  // The formula below calculates the first day of the ISO week
  // ISO weeks start on Monday, so we adjust to start on Sunday by subtracting 1 day
  const dayOffset = 24 * 60 * 60 * 1000; // milliseconds in a day
  const weekOffset = (weekNum - 1) * 7 * dayOffset;
  const firstDayOfWeek = new Date(firstDayOfYear.getTime() + weekOffset);
  
  // Adjust to get the Sunday of the week
  const dayOfWeek = firstDayOfWeek.getDay(); // 0 = Sunday, 1 = Monday, etc.
  const diff = firstDayOfWeek.getDate() - dayOfWeek + (dayOfWeek === 0 ? 0 : -1);
  const sunday = new Date(firstDayOfWeek.setDate(diff));
  
  // Calculate the last day of the week (Saturday)
  const saturday = new Date(sunday);
  saturday.setDate(sunday.getDate() + 6);
  
  // Format the dates
  const formatOptions = { month: 'long', day: 'numeric' };
  const startDateStr = sunday.toLocaleDateString('en-US', formatOptions);
  
  // For the end date, include the year
  const endDateOptions = { ...formatOptions, year: 'numeric' };
  const endDateStr = saturday.toLocaleDateString('en-US', endDateOptions);
  
  return `${startDateStr} - ${endDateStr}`;
};
