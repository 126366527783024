import React from 'react';
import { Container, Typography, Box, Paper, Grid, Card, CardMedia } from '@mui/material';
import { motion } from 'framer-motion';

function MarketingPage() {
  return (
    <>
    <Container maxWidth="lg">
      <Box sx={{ mt: 8, mb: 4, textAlign: 'center' }}>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h3" component="h1" gutterBottom>
            Welcome to Vital Reflection
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h6" sx={{ mt: 2, mb: 4 }}>
            Discover the transformative power of daily and weekly reflection.
          </Typography>
        </motion.div>
      </Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Card>
<CardMedia
  component="img"
  height="300"
  image="/images/vital_01.png"
  alt="Vital Image 1"
/>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
            <Typography variant="h5" gutterBottom>
              Why Reflect Daily?
            </Typography>
            <Typography variant="body1">
              Daily reflection helps you:
              <ul>
                <li>Gain mental clarity and focus</li>
                <li>Process emotions and reduce stress</li>
                <li>Track progress towards personal goals</li>
              </ul>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
            <Typography variant="h5" gutterBottom>
              Benefits of Weekly Reflection
            </Typography>
            <Typography variant="body1">
              Weekly reflection allows you to:
              <ul>
                <li>Identify patterns and trends in your life</li>
                <li>Celebrate achievements and milestones</li>
                <li>Set meaningful goals for the future</li>
              </ul>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
<CardMedia
  component="img"
  height="300"
  image="/images/vital_02.png"
  alt="Vital Image 2"
/>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            Start Your Journey Today!
          </Typography>
          <Typography variant="body1">
            Join thousands of users who have transformed their lives through reflection and mindfulness.
          </Typography>
        </motion.div>
      </Box>
    <Box sx={{ mt: 4, textAlign: 'center', py: 2 }}>
      <Typography variant="body2" color="textSecondary">
        © {new Date().getFullYear()} Vital Reflection. All rights reserved.
      </Typography>
    </Box>
    </Container>
    </>
  );
}

export default MarketingPage;
