import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0E1E33', // Dark navy blue from logo background
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#4ECDC4', // Teal/turquoise from logo
    },
    accent1: {
      main: '#FF9D5C', // Orange from logo
    },
    accent2: {
      main: '#FFF5E1', // Cream/white from logo
    },
    background: {
      default: '#F5F5F5',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h6: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#0E1E33', // Dark navy blue from logo background
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});

export default theme;
