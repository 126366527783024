/**
 * Utility functions for analyzing themes and patterns in responses
 */

/**
 * Extract common words and potential themes from text responses
 * @param {Array} responses - Array of response objects
 * @param {Array} fields - Fields to analyze for themes
 * @returns {Object} Object containing theme analysis
 */
export const extractThemes = (responses, fields) => {
  if (!responses || responses.length === 0) {
    return { commonWords: {}, topThemes: [] };
  }

  // Combine all text from specified fields
  let allText = '';
  responses.forEach(response => {
    if (!response) return;
    
    fields.forEach(field => {
      if (response[field]) {
        allText += ' ' + response[field].toLowerCase();
      }
    });
  });

  // Remove common stop words and punctuation
  const stopWords = new Set([
    'a', 'an', 'the', 'and', 'or', 'but', 'in', 'on', 'at', 'to', 'for', 'with',
    'by', 'about', 'as', 'into', 'like', 'through', 'after', 'over', 'between',
    'out', 'of', 'during', 'without', 'before', 'under', 'around', 'among',
    'i', 'me', 'my', 'myself', 'we', 'our', 'ours', 'ourselves', 'you', 'your',
    'yours', 'yourself', 'yourselves', 'he', 'him', 'his', 'himself', 'she',
    'her', 'hers', 'herself', 'it', 'its', 'itself', 'they', 'them', 'their',
    'theirs', 'themselves', 'what', 'which', 'who', 'whom', 'this', 'that',
    'these', 'those', 'am', 'is', 'are', 'was', 'were', 'be', 'been', 'being',
    'have', 'has', 'had', 'having', 'do', 'does', 'did', 'doing', 'would',
    'should', 'could', 'ought', 'i\'m', 'you\'re', 'he\'s', 'she\'s', 'it\'s',
    'we\'re', 'they\'re', 'i\'ve', 'you\'ve', 'we\'ve', 'they\'ve', 'i\'d',
    'you\'d', 'he\'d', 'she\'d', 'we\'d', 'they\'d', 'i\'ll', 'you\'ll',
    'he\'ll', 'she\'ll', 'we\'ll', 'they\'ll', 'isn\'t', 'aren\'t', 'wasn\'t',
    'weren\'t', 'hasn\'t', 'haven\'t', 'hadn\'t', 'doesn\'t', 'don\'t',
    'didn\'t', 'won\'t', 'wouldn\'t', 'shan\'t', 'shouldn\'t', 'can\'t',
    'cannot', 'couldn\'t', 'mustn\'t', 'let\'s', 'that\'s', 'who\'s',
    'what\'s', 'here\'s', 'there\'s', 'when\'s', 'where\'s', 'why\'s',
    'how\'s', 'not', 'very', 'too', 'just', 'now', 'more', 'most', 'only',
    'than', 'then', 'so', 'also', 'some', 'such', 'no', 'nor', 'all', 'any',
    'every', 'few', 'many', 'other', 'some', 'such', 'from', 'up', 'down'
  ]);

  // Clean the text and split into words
  const cleanedText = allText.replace(/[^\w\s]/g, ' ').replace(/\s+/g, ' ').trim();
  const words = cleanedText.split(' ').filter(word => 
    word.length > 3 && !stopWords.has(word)
  );

  // Count word frequencies
  const wordFrequency = {};
  words.forEach(word => {
    wordFrequency[word] = (wordFrequency[word] || 0) + 1;
  });

  // Sort words by frequency
  const sortedWords = Object.entries(wordFrequency)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 30)
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  // Extract potential themes (words that appear multiple times)
  const topThemes = Object.entries(sortedWords)
    .filter(([_, count]) => count > 1)
    .map(([word, count]) => ({
      word,
      count,
      percentage: Math.round((count / responses.length) * 100)
    }))
    .slice(0, 10);

  return {
    commonWords: sortedWords,
    topThemes
  };
};

/**
 * Calculate rating trends over time
 * @param {Array} responses - Array of weekly response objects
 * @returns {Object} Object containing rating trends
 */
export const calculateRatingTrends = (responses) => {
  if (!responses || responses.length === 0) {
    return { wellbeing: [], progress: [] };
  }

  // Sort responses by date/week
  const sortedResponses = [...responses].sort((a, b) => {
    // For weekly responses, sort by week key
    if (a.week && b.week) {
      return a.week.localeCompare(b.week);
    }
    // For daily responses, sort by date
    if (a.date && b.date) {
      return a.date.localeCompare(b.date);
    }
    return 0;
  });

  // Extract ratings
  const wellbeingRatings = sortedResponses.map(response => ({
    date: response.week || response.date,
    rating: response.responses?.wellbeingRating || 0
  })).filter(item => item.rating > 0);

  const progressRatings = sortedResponses.map(response => ({
    date: response.week || response.date,
    rating: response.responses?.progressRating || 0
  })).filter(item => item.rating > 0);

  // Calculate averages
  const wellbeingAvg = wellbeingRatings.length > 0
    ? wellbeingRatings.reduce((sum, item) => sum + item.rating, 0) / wellbeingRatings.length
    : 0;

  const progressAvg = progressRatings.length > 0
    ? progressRatings.reduce((sum, item) => sum + item.rating, 0) / progressRatings.length
    : 0;

  return {
    wellbeing: wellbeingRatings,
    progress: progressRatings,
    averages: {
      wellbeing: parseFloat(wellbeingAvg.toFixed(1)),
      progress: parseFloat(progressAvg.toFixed(1))
    }
  };
};

/**
 * Group responses by recalibration status
 * @param {Array} responses - Array of daily response objects
 * @returns {Object} Object containing grouped responses
 */
export const analyzeRecalibration = (responses) => {
  if (!responses || responses.length === 0) {
    return { recalibrated: 0, notRecalibrated: 0, percentage: 0 };
  }

  const recalibrated = responses.filter(r => 
    r.responses && r.responses.recalibrated === true
  ).length;
  
  const notRecalibrated = responses.filter(r => 
    r.responses && r.responses.recalibrated === false
  ).length;

  const total = recalibrated + notRecalibrated;
  const percentage = total > 0 ? Math.round((recalibrated / total) * 100) : 0;

  return {
    recalibrated,
    notRecalibrated,
    percentage
  };
};

/**
 * Format data for theme visualization
 * @param {Array} themes - Array of theme objects
 * @returns {Object} Formatted data for visualization
 */
export const formatThemeData = (themes) => {
  return themes.map(theme => ({
    theme: theme.word,
    count: theme.count,
    percentage: theme.percentage
  }));
};
