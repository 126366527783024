import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import './App.css';
import MarketingPage from './components/MarketingPage';

// Components
import SignUp from './components/SignUp';
import Login from './components/Login';
import PasswordReset from './components/PasswordReset';
import DailyReflection from './components/DailyReflection';
import WeeklyReflection from './components/WeeklyReflection';
import PastResponses from './components/PastResponses';
import UserProfile from './components/UserProfile';
import AnimatedBackground from './components/AnimatedBackground';
import Navigation from './components/Navigation';

// Context
import { AuthProvider, useAuth } from './contexts/AuthContext';

// Protected route component
function PrivateRoute({ children }) {
  const { currentUser } = useAuth();
  
  return currentUser ? children : <Navigate to="/login" />;
}

// Component to handle route changes and control animation
function RouteObserver() {
  const location = useLocation();
  
  useEffect(() => {
    // Pages where animation should be paused
    const pauseAnimationPages = ['/daily', '/weekly', '/past', '/profile'];
    
    // Check if current path should have animation paused
    const shouldPauseAnimation = pauseAnimationPages.includes(location.pathname);
    
    if (shouldPauseAnimation) {
      // Dispatch event to pause animation
      window.dispatchEvent(new Event('pauseAnimation'));
    } else {
      // Dispatch event to resume animation
      window.dispatchEvent(new Event('resumeAnimation'));
    }
  }, [location]);
  
  return null;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <AnimatedBackground />
          <RouteObserver />
          <Navigation />
        <Routes>
          {/* Redirect root to daily reflection */}
          <Route path="/" element={<Navigate to="/daily" replace />} />
          
          {/* Protected routes */}
          <Route path="/daily" element={
            <PrivateRoute>
              <DailyReflection />
            </PrivateRoute>
          } />
          
          <Route path="/weekly" element={
            <PrivateRoute>
              <WeeklyReflection />
            </PrivateRoute>
          } />
          
          <Route path="/past" element={
            <PrivateRoute>
              <PastResponses />
            </PrivateRoute>
          } />
          
          <Route path="/profile" element={
            <PrivateRoute>
              <UserProfile />
            </PrivateRoute>
          } />
          
          {/* Public routes */}
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/marketing" element={<MarketingPage />} />
          </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
