// Firebase configuration
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZYUou7svzBIf_uy5-1PTy1B7K5czR-B0",
  authDomain: "vitalume-29e8a.firebaseapp.com",
  projectId: "vitalume-29e8a",
  storageBucket: "vitalume-29e8a.firebasestorage.app",
  messagingSenderId: "882825063178",
  appId: "1:882825063178:web:44b6d9cc01f2b0cbdd28f3",
  measurementId: "G-SYTZG80NM9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

console.log('Firebase initialized successfully');
