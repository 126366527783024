import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  TextField, 
  Button, 
  Paper, 
  Grid, 
  Checkbox, 
  FormControlLabel,
  Card,
  CardContent,
  Divider
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { 
  getTodayDateString, 
  loadDailyResponses, 
  saveDailyResponses, 
  createEmptyDailyResponses
} from '../utils/responseUtils';

function DailyReflection() {
  const { currentUser } = useAuth();
  const [dailyResponses, setDailyResponses] = useState(createEmptyDailyResponses());
  const [savedResponses, setSavedResponses] = useState(null);
  const [saveStatus, setSaveStatus] = useState('');
  const [loading, setLoading] = useState(true);

  // Load saved responses for today
  useEffect(() => {
    async function fetchDailyResponses() {
      if (!currentUser) {
        setLoading(false);
        return;
      }
      
      try {
        const todayDateString = getTodayDateString();
        const todayResponses = await loadDailyResponses(currentUser.email, todayDateString);
        
        if (todayResponses) {
          setDailyResponses(todayResponses);
          setSavedResponses(todayResponses);
        } else {
          setDailyResponses(createEmptyDailyResponses());
          setSavedResponses(null);
        }
      } catch (error) {
        console.error("Error loading daily responses:", error);
      } finally {
        setLoading(false);
      }
    }
    
    fetchDailyResponses();
  }, [currentUser]); // Only run when currentUser changes

  // Handle input changes
  const handleInputChange = useCallback((field) => (event) => {
    const value = field === 'recalibrated' ? event.target.checked : event.target.value;
    setDailyResponses(prev => ({
      ...prev,
      [field]: value
    }));
  }, []);

  // Save responses to Firestore
  const handleSave = useCallback(async () => {
    if (!currentUser) return;
    
    try {
      setSaveStatus('Saving...');
      const dateKey = getTodayDateString();
      await saveDailyResponses(currentUser.email, dateKey, dailyResponses);
      setSavedResponses({...dailyResponses});
      setSaveStatus('Responses saved successfully!');
    } catch (error) {
      console.error("Error saving daily responses:", error);
      setSaveStatus('Error saving responses. Please try again.');
    }
    
    // Clear save status after 3 seconds
    setTimeout(() => {
      setSaveStatus('');
    }, 3000);
  }, [currentUser, dailyResponses]);

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
          <Typography variant="h6">Loading daily reflection data...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Daily Reflection
        </Typography>
        
        <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Today's Questions - {getTodayDateString()}
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="What is/will be your daily highlight?"
                variant="outlined"
                value={dailyResponses?.highlight || ''}
                onChange={handleInputChange('highlight')}
                multiline
                rows={2}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="What are you grateful for? (Capture a memory of graditude)"
                variant="outlined"
                value={dailyResponses?.gratitude || ''}
                onChange={handleInputChange('gratitude')}
                multiline
                rows={2}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="What are you personally proud of today?"
                variant="outlined"
                value={dailyResponses?.proud || ''}
                onChange={handleInputChange('proud')}
                multiline
                rows={2}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="What did/will you learn today?"
                variant="outlined"
                value={dailyResponses?.learned || ''}
                onChange={handleInputChange('learned')}
                multiline
                rows={2}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="What did/will you create today?"
                variant="outlined"
                value={dailyResponses?.create || ''}
                onChange={handleInputChange('create')}
                multiline
                rows={2}
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(dailyResponses?.recalibrated)}
                    onChange={handleInputChange('recalibrated')}
                    color="primary"
                  />
                }
                label="Did you recalibrate?"
              />
            </Grid>
          </Grid>
          
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSave}
            >
              Save Responses
            </Button>
          </Box>
          
          {saveStatus && (
            <Typography 
              variant="subtitle1" 
              color="primary" 
              sx={{ mt: 2, textAlign: 'center' }}
            >
              {saveStatus}
            </Typography>
          )}
        </Paper>
        
        {savedResponses && (
          <Card sx={{ mt: 4 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Today's Saved Responses
              </Typography>
              <Divider sx={{ mb: 2 }} />
              
              <Typography variant="body1" gutterBottom>
                <strong>Daily Highlight:</strong> {savedResponses.highlight || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Gratitude:</strong> {savedResponses.gratitude || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Proud of:</strong> {savedResponses.proud || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Learned:</strong> {savedResponses.learned || ''}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                <strong>Will Create:</strong> {savedResponses.create || ''}
              </Typography>
              
              <Typography variant="body1">
                <strong>Recalibrated:</strong> {savedResponses.recalibrated ? 'Yes' : 'No'}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
    </Container>
  );
}

export default DailyReflection;
