import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Box, 
  IconButton, 
  Menu, 
  MenuItem,
  useMediaQuery,
  useTheme,
  Avatar
} from '@mui/material';
import Logo from './Logo';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from '../contexts/AuthContext';
import { getUserProfilePicture } from '../utils/profileUtils';

function Navigation() {
  const { currentUser, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  
  // Load profile picture
  useEffect(() => {
    async function loadProfilePicture() {
      if (currentUser) {
        try {
          const picture = await getUserProfilePicture(currentUser.email);
          setProfilePicture(picture);
        } catch (error) {
          console.error("Error loading profile picture:", error);
        }
      }
    }
    
    loadProfilePicture();
  }, [currentUser, location]); // Re-check when location changes (e.g., after profile update)
  
  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };
  
  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };
  
  const handleUserMenuOpen = (event) => {
    setUserMenuAnchor(event.currentTarget);
  };
  
  const handleUserMenuClose = () => {
    setUserMenuAnchor(null);
  };
  
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Failed to log out", error);
    }
    handleUserMenuClose();
  };
  
  // Navigation links
  const navLinks = [
    { path: '/daily', label: 'Daily Reflection' },
    { path: '/weekly', label: 'Weekly Reflection' },
    { path: '/past', label: 'Past Responses' }
  ];
  
  // Check if a path is active
  const isActive = (path) => {
    return location.pathname === path;
  };
  
  // Only show navigation if user is logged in
  if (!currentUser) return null;
  
  return (
    <AppBar position="static" color="primary" elevation={1}>
      <Toolbar>
        <Box 
          component={Link} 
          to="/"
          sx={{ 
            flexGrow: 1, 
            textDecoration: 'none', 
            color: 'inherit',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Logo height={40} showText={!isMobile} />
        </Box>
        
        {isMobile ? (
          // Mobile navigation
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            
            <Menu
              anchorEl={mobileMenuAnchor}
              open={Boolean(mobileMenuAnchor)}
              onClose={handleMobileMenuClose}
            >
              {navLinks.map((link) => (
                <MenuItem 
                  key={link.path}
                  component={Link}
                  to={link.path}
                  onClick={handleMobileMenuClose}
                  selected={isActive(link.path)}
                >
                  {link.label}
                </MenuItem>
              ))}
              <MenuItem 
                component={Link} 
                to="/profile"
                onClick={handleMobileMenuClose}
              >
                My Profile
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        ) : (
          // Desktop navigation
          <>
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              {navLinks.map((link) => (
                <Button
                  key={link.path}
                  component={Link}
                  to={link.path}
                  color="inherit"
                  sx={{ 
                    mx: 1,
                    fontWeight: isActive(link.path) ? 'bold' : 'normal',
                    borderBottom: isActive(link.path) ? '2px solid #4ECDC4' : 'none',
                    '&:hover': {
                      backgroundColor: 'rgba(78, 205, 196, 0.1)'
                    }
                  }}
                >
                  {link.label}
                </Button>
              ))}
            </Box>
            
            <Box>
              {profilePicture ? (
                <IconButton
                  onClick={handleUserMenuOpen}
                  sx={{ p: 0 }}
                >
                  <Avatar 
                    src={profilePicture} 
                    sx={{ 
                      width: 40, 
                      height: 40,
                      border: '2px solid white'
                    }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  color="inherit"
                  onClick={handleUserMenuOpen}
                >
                  <AccountCircleIcon />
                </IconButton>
              )}
              
              <Menu
                anchorEl={userMenuAnchor}
                open={Boolean(userMenuAnchor)}
                onClose={handleUserMenuClose}
              >
                <MenuItem disabled>
                  {currentUser.email}
                </MenuItem>
                <MenuItem 
                  component={Link} 
                  to="/profile"
                  onClick={handleUserMenuClose}
                >
                  My Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navigation;
